<template>
  <div>
    <div id="login" v-if="$route.path == '/login'">
      <router-view />
    </div>
    <div id="app" v-else>
      <nav>
        <header>
          <h2>Keuangan</h2>
        </header>
        <ul>
          <li>
            <router-link
              to="/"
              :class="{ active: $store.state.setting.activePage == 'home' }"
            >
              Home</router-link
            >
          </li>

          <li class="separator">
            <span></span>
          </li>

          <li>
            <router-link
              to="/pengajuan"
              :class="{
                active: $store.state.setting.activePage == 'pengajuan',
              }"
            >
              Pengajuan
            </router-link>
          </li>
          <li>
            <router-link
              to="/realisasi"
              :class="{
                active: $store.state.setting.activePage == 'realisasi',
              }"
            >
              Realisasi

              <span v-show="notif.realisasi > 0" class="notif">{{
                notif.realisasi
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/transaksi"
              :class="{
                active: $store.state.setting.activePage == 'transaksi',
              }"
            >
              Transaksi
            </router-link>
          </li>
          <li>
            <router-link
              to="/laporan"
              :class="{
                active: $store.state.setting.activePage == 'laporan',
              }"
            >
              Laporan
            </router-link>
          </li>

          <li class="separator">
            <span></span>
          </li>

          <li>
            <router-link
              to="/pembayaran-siswa"
              :class="{
                active: $store.state.setting.activePage == 'pembayaran-siswa',
              }"
            >
              Pembayaran Siswa
            </router-link>
          </li>

          <li class="separator" v-if="user.akses == 'HEAD OFFICE'">
            <span></span>
          </li>

          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link
              to="/resume/realisasi"
              :class="{
                active: $store.state.setting.activePage == 'realisasi/resume',
              }"
            >
              Resume Realisasi
            </router-link>
          </li>
          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link
              to="/resume/transaksi"
              :class="{
                active: $store.state.setting.activePage == 'resume/transaksi',
              }"
            >
              Resume Transaksi
            </router-link>
          </li>

          <li class="separator" v-if="user.akses == 'HEAD OFFICE'">
            <span></span>
          </li>

          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link
              to="/pos"
              :class="{ active: $store.state.setting.activePage == 'pos' }"
            >
              Master POS
            </router-link>
          </li>
          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link
              to="/perusahaan"
              :class="{
                active: $store.state.setting.activePage == 'perusahaan',
              }"
            >
              Perusahaan
            </router-link>
          </li>
          <li v-if="user.akses == 'HEAD OFFICE'">
            <router-link
              to="/user"
              :class="{ active: $store.state.setting.activePage == 'user' }"
            >
              User
            </router-link>
          </li>
        </ul>
      </nav>
      <article>
        <div class="logout" title="Logout">
          <p>{{ user.nama }} @ {{ user.perusahaan }}</p>
          <span
            class="material-symbols-outlined bold notifications"
            @click="openNotifikasi()"
          >
            notifications

            <small v-show="newNotif" class="badge"></small>

            <div class="notifications-list" v-show="showNotif">
              <ul>
                <li
                  v-for="(item, index) in dataNotifikasi"
                  :key="index"
                  :class="item.dibuka ? 'dibaca' : ''"
                >
                  <p v-html="item.judul"></p>
                  <small>{{
                    moment(item.created_at).format("DD/MM/YY HH:mm")
                  }}</small>
                </li>
              </ul>
            </div>
          </span>
          <span class="material-symbols-outlined bold" @click="logout()">
            logout
          </span>
        </div>
        <router-view />
        <div class="loading" v-show="$store.state.setting.loading == true">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </article>
      <footer>
        <small> <b>CONFIDENTIAL</b> and for internal use only </small>
      </footer>
    </div>

    <div class="overlay" v-show="showNotif" @click="closeNotif"></div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "./api";
import moment from "moment";

export default {
  data() {
    return {
      user: {},
      notif: {},

      showNotif: false,
      newNotif: false,

      dataNotifikasi: [],
    };
  },
  methods: {
    moment,
    closeNotif(e) {
      if (e.target.classList.contains("overlay")) {
        this.showNotif = false;
      }
    },
    openNotifikasi() {
      this.showNotif = true;

      setTimeout(() => {
        this.newNotif = false;
        apiPost(`/v1/notifikasi/open`);
        this.loadNotif();
      }, 1500);
    },
    async loadNotif() {
      const res = await apiGet("/v1/notifikasi");
      const data = res.data;

      data.forEach((item) => {
        if (!item.dibuka) {
          this.newNotif = true;
        }
      });

      this.dataNotifikasi = data;
    },
    async countNotif() {
      const res = await apiGet("/v1/pengajuan/count");
      const data = res.data;

      this.notif.realisasi = data;
    },
    logout() {
      localStorage.clear();
      location.href = "/";
    },
  },
  mounted() {
    if (this.$route.path != "/login") {
      if (!this.$store.state.setting.isLogin) {
        this.$router.push("/login");
      } else {
        this.user = this.$store.state.setting.login;
        this.loadNotif();

        if (this.user.akses == "HEAD OFFICE") {
          this.countNotif();
        }
      }
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:wght@400;600&display=swap");

:root {
  --primary-disabled: #727577;
  --primary: #3b4852;
  --primary-darker: #2b3846;
  --green-disabled: #52c08c;
  --green: #0a9d58;
  --green-darker: #05884b;
  --red: #f00;

  --light-blue: #e6f4ff;
  --blue-disabled: #95c1ff;
  --blue: #1677ff;
  --blue-hover: #4096ff;
  --blue-active: #0958d9;
}

* {
  font-family: "Wix Madefor Text", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  color: var(--primary);
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  &.bold {
    font-variation-settings: "FILL" 0, "wght" 900, "GRAD" 0, "opsz" 48;
  }
}

#app {
  height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template:
    "nav    article " 1fr
    "nav    footer  " 50px /
    260px 1fr;

  header {
    h2 {
      margin: 10px 0 20px;
      text-align: center;
    }
  }

  article {
    grid-area: article;
    padding: 10px 30px 20px;
    position: relative;
  }

  nav {
    grid-area: nav;
    padding: 20px 10px 20px 20px;
    border-right: 1px solid #ddd;
    ul {
      margin: 50px 0 0 0;
      padding: 0;
      list-style-type: none;
      height: calc(100% - 100px);
      overflow: auto;
      li {
        a {
          display: flex;
          align-items: center;
          padding: 15px;
          color: var(--primary);
          text-decoration: none;
          border-radius: 5px;
          margin: 5px 0;
          transition: border-color 0.3s, background 0.3s,
            padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

          span {
            margin-right: 5px;
          }
          &:hover {
            background-color: #eee;
          }
          &.active {
            background-color: var(--light-blue);
            color: var(--blue);

            span {
              color: var(--blue);

              &.notif {
                color: #fff;
              }
            }
          }
        }
        &.separator {
          span {
            display: block;
            height: 20px;
          }
        }

        span.notif {
          background-color: #f00;
          color: #fff;
          width: 24px;
          height: 24px;
          border-radius: 30px;
          font-size: 13px;
          font-weight: bold;
          text-align: center;
          line-height: 24px;
          margin-left: 10px;
        }
      }
    }
  }

  footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 30px;
    border-top: 1px solid #ddd;
    small {
      b {
        color: #777;
      }
    }
  }
}

.card {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 5px;
  }
}

input,
select,
textarea {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: 15px;
  &:focus {
    border: 1px solid var(--blue);
  }

  &.invalid {
    border: 1px solid var(--red);
  }
}

.btn {
  display: block;
  background-color: var(--blue);
  color: #fff;
  border: 0; //1px solid var(--blue);
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  padding: 12px;
  text-decoration: none;
  text-align: center;
  font-size: 14px;

  &:disabled {
    background-color: var(--blue-disabled);
    &:hover {
      background-color: var(--blue-disabled);
    }
  }

  &.btn-outline {
    background-color: #fff;
    border: 1px solid var(--blue);
    color: var(--blue);
    &:active {
      background-color: #eee;
    }
  }

  &.btn-green {
    background-color: var(--green);
    border: 1px solid var(--green);
    &:active {
      background-color: var(--green-darker);
      border: 1px solid var(--green-darker);
    }
    &:disabled {
      background-color: var(--green-disabled);
    }
  }

  &:active {
    background-color: var(--blue-active);
  }
}

.logout {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  p {
    margin: 0;
  }
  span {
    cursor: pointer;

    &.notifications {
      position: relative;
      small.badge {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 20px;
        background: #f00;
        top: -5px;
        right: -5px;
      }

      .notifications-list {
        position: absolute;
        background: #fff;
        right: 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        z-index: 9;
        margin-top: 15px;
        ul {
          list-style-type: none;
          margin: 0;
          padding: 20px;
          li {
            font-size: 16px;
            padding: 15px 0;
            border-bottom: 1px solid #ccc;

            &.dibaca {
              font-variation-settings: normal;
            }
            p {
              margin: 0;
              padding: 6px 0;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.table-inline {
  table {
    tr {
      td {
        padding: 5px 0 2px;
        &:first-child {
          padding-right: 10px;
        }
        &:nth-child(2) {
          padding-right: 20px;
        }
      }
    }
  }
}

.table-wrapper {
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 6px;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
          background-color: #eee;
          padding: 10px;
          text-align: left;
        }
      }
    }
    tbody,
    tfoot {
      tr {
        cursor: pointer;
        &:hover {
          td {
            background-color: #f7f7f7;
          }
          &.no-hover {
            td {
              background-color: #fff;
              cursor: default;
            }
          }
        }

        &:active {
          td {
            background-color: #eee;
          }
        }
        td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          font-size: 14px;
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
      .btn-hapus {
        color: var(--red);
        padding: 4px 8px;
        border-radius: 6px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background: var(--red);
          color: #fff;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 9;
  .modal-body {
    padding: 20px;
    margin-top: 50px;
    background-color: #fff;
    border-radius: 6px;
    height: max-content;
    min-width: 400px;
    max-height: 60vh;
    overflow: auto;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.75);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vue-daterange-picker {
  display: block !important;
}
.daterangepicker:before,
.daterangepicker:after {
  border: 0;
}
.reportrange-text {
  padding: 10px !important;
  border-radius: 4px !important;
  font-size: 15px;
}
</style>
