<template>
  <div class="home">
    <h1>Pengajuan</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link class="btn" to="/pengajuan/-1">
            INPUT PENGAJUAN
          </router-link>
        </div>
        <div class="filter">
          <p>Filter</p>
          <form
            @submit.prevent="load()"
            :class="
              $store.state.setting.login.akses == 'HEAD OFFICE' ? 'ho' : ''
            "
          >
            <input
              type="date"
              placeholder="Tgl Order"
              v-model="filter.tgl_order"
            />
            <select v-model="filter.is_realisasi">
              <option value="-1">Belum Realisasi</option>
              <option value="1">Sudah Realisasi</option>
            </select>
            <select
              v-model="filter.id_perusahaan"
              v-if="$store.state.setting.login.akses == 'HEAD OFFICE'"
            >
              <option value="SEMUA">Semua Perusahaan</option>
              <option
                :value="item.id"
                v-for="(item, index) in dataPerusahaan"
                :key="index"
              >
                {{ item.nama }}
              </option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tanggal</th>
              <th>Kebutuhan</th>
              <th style="text-align: right">Jumlah</th>
              <th style="text-align: right">Realisasi</th>
              <th>Keterangan</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              @click="$to(`/pengajuan/${item.id}`)"
            >
              <td v-if="item.group" colspan="7" style="background: #f7f7f7;text-transform: uppercase;">
                <b>{{ item.perusahaan }}</b>                
              </td>

              <td v-if="!item.group">{{ ++index }}</td>
              <td v-if="!item.group">{{ moment(item.tgl_pengajuan).format("DD/MM/YYYY") }}</td>
              <td v-if="!item.group">{{ item.kebutuhan }}</td>
              <td v-if="!item.group" align="right">{{ item.jumlah | number }}</td>
              <td v-if="!item.group" align="right">
                {{ item.is_realisasi !== 1 ? 0 : item.realisasi | number }}
              </td>
              <td v-if="!item.group">{{ item.keterangan || "-" }}</td>
              <td v-if="!item.group" align="right">
                <span
                  class="btn-hapus"
                  @click.stop="hapus(item)"
                  v-if="item.is_realisasi != 1"
                  >Hapus</span
                >
              </td>
            </tr>

            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="8">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PengajuanForm @reload="load()" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

import { apiGet, apiPost } from "../api";

import PengajuanForm from "./PengajuanForm.vue";

export default {
  name: "Order",
  components: {
    PengajuanForm,
  },
  data() {
    return {
      modal: false,
      data: [],
      dataPerusahaan: [],
      filter: {
        is_realisasi: -1,
        id_perusahaan: "SEMUA",
      },
    };
  },
  methods: {
    moment,
    openModal(val) {
      this.modal = val;
    },
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.$loading(true);
      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/pengajuan?${filter}`);

      setTimeout(() => {
        this.data = res.data;

        if (this.$store.state.setting.login.akses == "HEAD OFFICE") {
          const group = _.groupBy(this.data, "perusahaan");

          this.data = [];
          Object.keys(group).forEach((perusahaan) => {
            this.data.push({
              group: true,
              perusahaan,
            });

            group[perusahaan].forEach((item) => {
              this.data.push(item);
            });
          });
        }

        this.$loading(false);
      }, 150);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        const res = await apiPost(`/v1/pengajuan/delete`, { id: item.id });
        if (res.valid) {
          this.load();
        }
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pengajuan");

    this.loadPerusahaan();
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 200px 200px 100px;
    &.ho {
      grid-template-columns: 200px 200px 200px 100px;
    }
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 240px);
}
</style>