<template>
  <div class="pengajuan-form">
    <h1>Input Pembayaran</h1>

    <div class="content" ref="content">
      <form class="table-inline" @submit.prevent="save">
        <div class="table-group">
          <div>
            <p>
              <b>Cari Siswa</b>
            </p>
            <table>
              <tr>
                <td>No Daftar</td>
                <td>:</td>
                <td style="position: relative">
                  <input
                    type="text"
                    v-model="noDaftar"
                    @keydown.enter.prevent="handleKeyUp"
                    placeholder="Cari siswa berdasarkan no daftar"
                    ref="input_nodaftar"
                  />
                  <small
                    v-show="loadingCari"
                    style="position: absolute; top: 17px; right: 10px"
                    >Mencari data...</small
                  >
                </td>
              </tr>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.nama_lengkap" />
                </td>
              </tr>
              <tr>
                <td>Angkatan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.angkatan_2" />
                </td>
              </tr>
              <tr>
                <td>Kampus</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.kampus" />
                </td>
              </tr>
              <tr>
                <td>Jurusan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.jurusan" />
                </td>
              </tr>
              <tr>
                <td>Marketing</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.marketing" />
                </td>
              </tr>
            </table>

            <p>
              <b>{{
                $store.state.setting.login.akses == "CABANG"
                  ? "Input Pembayaran"
                  : "Validasi Pembayaran"
              }}</b>
            </p>
            <table>
              <tr>
                <td>Tgl Bayar</td>
                <td>:</td>
                <td>
                  <date-range-picker
                    v-model="dateRange"
                    :single-date-picker="true"
                    :auto-apply="true"
                    :ranges="false"
                  >
                    <template v-slot:input="picker">
                      {{ moment(picker.startDate).format("DD-MM-YYYY") }}
                    </template>
                  </date-range-picker>
                </td>
              </tr>

              <tr>
                <td>Jumlah</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah"
                    v-thousand-separator
                    placeholder="Sampai 4 digit terakhir"
                    @input="updateJumlahFix"
                  />
                </td>
              </tr>
              <tr>
                <td>Jumlah Fix</td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    v-model="data.jumlah_fix"
                    v-thousand-separator
                  />
                </td>
              </tr>
              <tr>
                <td>Keterangan</td>
                <td>:</td>
                <td>
                  <input type="text" v-model="data.keterangan" />
                </td>
              </tr>
              <tr>
                <td>Bukti</td>
                <td>:</td>
                <td>
                  <input
                    type="file"
                    @change="onFileChange"
                    ref="input_file"
                    accept="image/*"
                  />
                  <div v-show="data.bukti" style="margin: 10px 0">
                    <a :href="data.bukti" target="_blank">
                      <img :src="data.bukti" width="100%" />
                    </a>
                  </div>
                </td>
              </tr>
              <tr v-if="$store.state.setting.login.akses == 'CABANG'">
                <td></td>
                <td></td>
                <td style="display: flex; gap: 15px">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    ref="btn_simpan"
                  >
                    SIMPAN
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline"
                    @click="$router.back()"
                  >
                    KEMBALI
                  </button>
                </td>
              </tr>

              <tr v-if="$store.state.setting.login.akses == 'HEAD OFFICE'">
                <td></td>
                <td></td>
                <td style="display: flex; gap: 15px">
                  <button type="button" class="btn btn-primary">
                    VALIDASI
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline"
                    @click="$router.back()"
                  >
                    KEMBALI
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div
              style="position:sticky;top:0"
            >
              <p style="margin-top: 0">
                <b>Biaya Pendidikan</b>
              </p>
              <div class="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 40px">#</th>
                      <th style="width: 200px">Keterangan</th>
                      <th style="width: 120px; text-align: right">Jumlah</th>
                      <th style="text-align: center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in data.biaya_pendidikan"
                      :key="index"
                      :class="{ lunas: item.status == 'LUNAS' }"
                    >
                      <td style="width: 40px">{{ ++index }}</td>
                      <td>{{ item.keterangan }}</td>
                      <td align="right">{{ item.total | number }}</td>
                      <td align="center">{{ item.status }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="border-top: 2px solid #ccc">
                      <td colspan="2">
                        <b>TOTAL BIAYA PENDIDIKAN</b>
                      </td>
                      <td align="right" style="padding-right: 10px">
                        <b>{{
                          data.biaya_pendidikan
                            ? data.biaya_pendidikan[3].sumBiaya
                            : "0" | number
                        }}</b>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <p>
                <b>History Pembayaran</b>
              </p>
              <div class="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 40px">#</th>
                      <th style="width: 120px">Tgl Bayar</th>
                      <th style="width: 200px; text-align: right">Jumlah</th>
                      <th style="text-align: center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in data.history_pembayaran"
                      :key="index"
                      :class="{ lunas: item.status == 'VALIDASI' }"
                    >
                      <td style="width: 40px">{{ ++index }}</td>
                      <td>
                        {{ moment(item.tgl_bayar).format("DD MMM YYYY") }}
                      </td>
                      <td align="right">{{ item.jumlah_fix | number }}</td>
                      <td align="center">{{ item.status || "-" }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="border-top: 2px solid #ccc">
                      <td colspan="2">
                        <b>TOTAL PEMBAYARAN</b>
                      </td>
                      <td align="right" style="padding-right: 10px">
                        <b>{{ totalBayar | number }}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <b>KURANG BAYAR</b>
                      </td>
                      <td align="right" style="padding-right: 10px">
                        <b>{{ data.kurangBayar | number }}</b>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost, apiUpload, rawGet } from "../api";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "PengajuanForm",
  data() {
    return {
      dataPerusahaan: [],
      noDaftar: "",
      data: {
        id: this.$route.params.id,
      },
      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
      },
      selectedFile: null,
      loadingCari: false,

      totalBayar: 0,
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    moment,
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
      }
    },
    closeModal() {
      this.$router.back();
      // this.$emit("closeModal");
    },
    async handleKeyUp() {
      this.loadingCari = true;
      this.$loading(true);

      // data siswa
      const res = await rawGet(
        "https://pspp-integrated.com/pspp_platform/api/api/v1/SiswaController/get?no_daftar=" +
          this.noDaftar
      );

      if (!res) {
        alert("Data siswa tidak ditemukan");
        this.noDaftar = "";
        this.loadingCari = false;
        this.$loading(false);
        this.data = {
          id: this.$route.params.id,
        };
        return;
      }

      // data biaya pendidikan
      const tmpData = res;
      const resBiayaPendidikan = await rawGet(
        "https://pspp-integrated.com/pspp_platform/keuangan-lama/pembayaran/getBiaya?id_jurusan=" +
          tmpData.id_jurusan
      );
      tmpData.biaya_pendidikan = resBiayaPendidikan;

      // remodel biayaPendidikan
      let sumBiaya = 0;
      tmpData.biaya_pendidikan.forEach((item) => {
        sumBiaya += parseInt(item.total);
        item.sumBiaya = sumBiaya;
      });

      // data history pembayaran
      const paramHistory = {
        id_siswa: tmpData.id,
        no_daftar: tmpData.no_daftar,
        nama_lengkap: tmpData.nama_lengkap,
        angkatan: tmpData.angkatan_2,
        kampus: tmpData.kampus,
        jurusan: tmpData.jurusan,
        marketing: tmpData.marketing,
      };
      const resHistory = await apiPost(
        `/v1/pembayaran-siswa/history`,
        paramHistory
      );
      tmpData.history_pembayaran = resHistory.data;

      // count total bayar to set status tahap
      this.totalBayar = 0;
      tmpData.history_pembayaran.forEach((item) => {
        if (item.status == "VALIDASI") {
          this.totalBayar += parseInt(item.jumlah_fix);
        }
      });

      tmpData.biaya_pendidikan.forEach((item) => {
        if (this.totalBayar >= item.sumBiaya) {
          item.status = "LUNAS";
        }
      });

      // kurang bayar =
      tmpData.kurangBayar =
        this.totalBayar - tmpData.biaya_pendidikan[3].sumBiaya;

      if (this.$route.params.id > 0) {
        const res = await apiGet(
          `/v1/pembayaran-siswa?id=${this.$route.params.id}`
        );
        const data = res.data;

        tmpData.tgl_bayar = data.tgl_bayar;
        tmpData.jumlah = data.jumlah;
        tmpData.jumlah_fix = data.jumlah_fix;
        tmpData.keterangan = data.keterangan;
        tmpData.bukti = data.bukti;
      }

      this.data = tmpData;
      this.loadingCari = false;
      this.$loading(false);
    },
    async load() {
      this.noDaftar = this.$route.query.no_daftar;
      await this.handleKeyUp();
    },
    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    async save() {
      this.data.id_pembayaran = this.$route.params.id;
      this.data.tgl_bayar = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );

      let formData = this.objectToFormData(this.data);
      formData.append("file", this.selectedFile);

      this.$refs.btn_simpan.disabled = true;

      const res = await apiUpload(`/v1/pembayaran-siswa`, formData);

      if (res.valid) {
        this.$toast("Berhasil disimpan");
        this.$router.back();

        // if (this.data.id < 0) {
        //   this.data = {
        //     id: -1,
        //     id_perusahaan: this.data.id_perusahaan,
        //     kebutuhan: null,
        //     jumlah: null,
        //     keterangan: null,
        //   };
        //   this.selectedFile = null;

        //   this.$refs.input_file.value = null;
        //   this.$refs.input_kebutuhan.focus();
        //   this.$refs.btn_simpan.disabled = false;

        //   this.$emit("reload");
        // } else {
        //   this.$emit("reload");
        //   this.$router.back();
        // }
      }
    },
    updateJumlahFix() {
      const addValue = this.data.jumlah.slice(-4);

      const jumlah = parseInt(this.data.jumlah.replace(/\./g, ""));
      const jumlahFix = jumlah - parseInt(addValue);

      this.data.jumlah_fix = jumlahFix;
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pembayaran-siswa");

    setTimeout(() => {
      this.$refs["input_nodaftar"].focus();
    }, 150);

    if (this.data.id > 0) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
.pengajuan-form {
  h1 {
    margin-top: 0;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .content {
    margin-top: 50px;
    height: calc(100vh - 160px);
    overflow: auto;
    &.active {
      width: 1100px;
    }

    .table-inline {
      input,
      textarea {
        width: calc(100% - 22px);
      }

      select {
        width: 100%;
      }

      .checkbox {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;
        input {
          width: 20px;
          height: 20px;
        }
      }

      table {
        width: 100%;
        tr {
          td {
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 10px;
            }

            &.btn-group {
              display: flex;
              gap: 10px;
            }
          }
        }
      }

      button {
        width: 150px;
      }
    }
  }

  .table-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;

    &.inactive {
      opacity: 0.5;
    }

    p {
      color: var(--blue);
    }
  }

  tr.lunas {
    color: green;
    font-weight: bold;
  }
}
</style>
<style>
.daterangepicker.openscenter {
  right: unset !important;
}
</style>