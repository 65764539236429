var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.path == '/login')?_c('div',{attrs:{"id":"login"}},[_c('router-view')],1):_c('div',{attrs:{"id":"app"}},[_c('nav',[_vm._m(0),_c('ul',[_c('li',[_c('router-link',{class:{ active: _vm.$store.state.setting.activePage == 'home' },attrs:{"to":"/"}},[_vm._v(" Home")])],1),_vm._m(1),_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'pengajuan',
            },attrs:{"to":"/pengajuan"}},[_vm._v(" Pengajuan ")])],1),_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'realisasi',
            },attrs:{"to":"/realisasi"}},[_vm._v(" Realisasi "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.notif.realisasi > 0),expression:"notif.realisasi > 0"}],staticClass:"notif"},[_vm._v(_vm._s(_vm.notif.realisasi))])])],1),_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'transaksi',
            },attrs:{"to":"/transaksi"}},[_vm._v(" Transaksi ")])],1),_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'laporan',
            },attrs:{"to":"/laporan"}},[_vm._v(" Laporan ")])],1),_vm._m(2),_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'pembayaran-siswa',
            },attrs:{"to":"/pembayaran-siswa"}},[_vm._v(" Pembayaran Siswa ")])],1),(_vm.user.akses == 'HEAD OFFICE')?_c('li',{staticClass:"separator"},[_c('span')]):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'realisasi/resume',
            },attrs:{"to":"/resume/realisasi"}},[_vm._v(" Resume Realisasi ")])],1):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'resume/transaksi',
            },attrs:{"to":"/resume/transaksi"}},[_vm._v(" Resume Transaksi ")])],1):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',{staticClass:"separator"},[_c('span')]):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',[_c('router-link',{class:{ active: _vm.$store.state.setting.activePage == 'pos' },attrs:{"to":"/pos"}},[_vm._v(" Master POS ")])],1):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',[_c('router-link',{class:{
              active: _vm.$store.state.setting.activePage == 'perusahaan',
            },attrs:{"to":"/perusahaan"}},[_vm._v(" Perusahaan ")])],1):_vm._e(),(_vm.user.akses == 'HEAD OFFICE')?_c('li',[_c('router-link',{class:{ active: _vm.$store.state.setting.activePage == 'user' },attrs:{"to":"/user"}},[_vm._v(" User ")])],1):_vm._e()])]),_c('article',[_c('div',{staticClass:"logout",attrs:{"title":"Logout"}},[_c('p',[_vm._v(_vm._s(_vm.user.nama)+" @ "+_vm._s(_vm.user.perusahaan))]),_c('span',{staticClass:"material-symbols-outlined bold notifications",on:{"click":function($event){return _vm.openNotifikasi()}}},[_vm._v(" notifications "),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.newNotif),expression:"newNotif"}],staticClass:"badge"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNotif),expression:"showNotif"}],staticClass:"notifications-list"},[_c('ul',_vm._l((_vm.dataNotifikasi),function(item,index){return _c('li',{key:index,class:item.dibuka ? 'dibaca' : ''},[_c('p',{domProps:{"innerHTML":_vm._s(item.judul)}}),_c('small',[_vm._v(_vm._s(_vm.moment(item.created_at).format("DD/MM/YY HH:mm")))])])}),0)])]),_c('span',{staticClass:"material-symbols-outlined bold",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" logout ")])]),_c('router-view'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.setting.loading == true),expression:"$store.state.setting.loading == true"}],staticClass:"loading"},[_vm._m(3)])],1),_vm._m(4)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNotif),expression:"showNotif"}],staticClass:"overlay",on:{"click":_vm.closeNotif}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Keuangan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"separator"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"separator"},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('small',[_c('b',[_vm._v("CONFIDENTIAL")]),_vm._v(" and for internal use only ")])])
}]

export { render, staticRenderFns }