<template>
  <div class="home">
    <h1>Perusahaan</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link to="/perusahaan/-1" class="btn">TAMBAH</router-link>
        </div>
        <div class="filter">
          <p>Filter</p>
          <form @submit.prevent="filterData()">
            <input type="text" v-model="filter.cari" placeholder="Cari..." />
            <button type="button" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th style="width: 50px">#</th>
              <th>Nama</th>
              <th>Alamat</th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              @click="$to(`/perusahaan/${item.id}`)"
            >
              <td>{{ ++index }}</td>
              <td>{{ item.nama }}</td>
              <td>{{ item.alamat }}</td>
              <td align="center">
                <span class="btn-hapus" title="Hapus" @click.stop="remove(item)">
                  Hapus
                </span>
              </td>
            </tr>
            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="5">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";

import moment from "moment";

export default {
  name: "Order",
  data() {
    return {
      tmpData:[],
      data: [],
      filter: {
        cari: "",
      },
    };
  },
  methods: {
    moment,
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);

      setTimeout(() => {
        this.data = res.data;
        this.tmpData = res.data;
        this.$loading(false);
      }, 150);
    },
    async remove(item) {
      if (confirm(`Hapus ${item.nama}?`)) {
        this.$loading(true);

        const res = await apiPost(`/v1/perusahaan/delete`, { id: item.id });

        if (res.valid) {
          this.load();
        }
      }
    },
    filterData() {
      this.$loading(true);

      setTimeout(() => {
        this.data = this.tmpData.filter((k) => k.nama.toLowerCase().indexOf(this.filter.cari.toLowerCase()) > -1);
        this.$loading(false);
      }, 150);
    },
  },
  mounted() {
    this.load();

    this.$store.commit("setting/setActivePage", "perusahaan");
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 250px 100px;
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 240px);
}
</style>