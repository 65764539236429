<template>
  <div class="pengajuan-form">
    <h1>Input Pengajuan {{ data.no_order }}</h1>

    <div class="content" ref="content">
      <form class="table-inline" @submit.prevent="save()">
        <table>
          <tr>
            <td>Tanggal</td>
            <td>:</td>
            <td>
              <date-range-picker
                v-model="dateRange"
                :single-date-picker="true"
                :auto-apply="true"
                :ranges="false"
              >
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("DD-MM-YYYY") }}
                </template>
              </date-range-picker>
            </td>
          </tr>
          <tr>
            <td>Kebutuhan</td>
            <td>:</td>
            <td>
              <input
                type="text"
                v-model="data.kebutuhan"
                ref="input_kebutuhan"
                :class="{ invalid: submitted && !data.kebutuhan }"
              />
            </td>
          </tr>
          <tr>
            <td>Jumlah</td>
            <td>:</td>
            <td>
              <input
                type="text"
                v-model="data.jumlah"
                v-thousand-separator
                :class="{ invalid: submitted && !data.jumlah }"
              />
            </td>
          </tr>
          <tr>
            <td>Keterangan</td>
            <td>:</td>
            <td>
              <input
                type="text"
                v-model="data.keterangan"
              />
            </td>
          </tr>
          <tr>
            <td>Bukti</td>
            <td>:</td>
            <td>
              <input
                type="file"
                @change="onFileChange"
                ref="input_file"
                accept="image/*"
              />
              <div v-show="data.bukti" class="bukti">
                <a :href="data.bukti" target="_blank">
                  <img :src="data.bukti" width="100%" />
                </a>
              </div>
            </td>
          </tr>
          <tr v-show="$store.state.setting.login.akses == 'HEAD OFFICE'">
            <td>Perusahaan</td>
            <td>:</td>
            <td>
              <select v-model="data.id_perusahaan">
                <option
                  :value="item.id"
                  v-for="(item, index) in dataPerusahaan"
                  :key="index"
                >
                  {{ item.nama }}
                </option>
              </select>
            </td>
          </tr>
          <tr v-if="data.is_realisasi != 1">
            <td></td>
            <td></td>
            <td class="btn-group">
              <button type="submit" class="btn" ref="btn_simpan">SIMPAN</button>
              <button
                type="button"
                class="btn btn-outline"
                @click="closeModal()"
              >
                TUTUP
              </button>
            </td>
          </tr>
          <tr v-if="data.is_realisasi == 1">
            <td></td>
            <td></td>
            <td>
              <p>Pengajuan yang sudah direalisasi tidak dapat diubah</p>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiUpload } from "./../api";
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "PengajuanForm",
  data() {
    return {
      dataPerusahaan: [],
      data: {
        id: this.$route.params.id,
      },
      dateRange: {
        startDate: moment().format("YYYY-MM-DD"),
      },
      selectedFile: null,

      submitted: false,
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    moment,
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
      }
    },
    closeModal() {
      this.$router.back();
      // this.$emit("closeModal");
    },
    async loadPerusahaan() {
      // this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;

      this.data.id_perusahaan = this.$store.state.setting.login.id_perusahaan;

      if (this.data.id < 0) {
        setTimeout(() => {
          this.$loading(false);
        }, 150);
      }
    },
    async load() {
      this.$refs.content.style.opacity = 0.5;
      const res = await apiGet(`/v1/pengajuan?id=${this.data.id}`);

      this.data = res.data;
      this.$refs.content.style.opacity = 1;
    },
    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    async save() {
      this.submitted = true;

      if (!this.data.kebutuhan || !this.data.jumlah) {
        return;
      }

      this.data.tgl_pengajuan = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.data.perusahaan = this.dataPerusahaan.find(
        (k) => k.id == this.data.id_perusahaan
      ).nama;

      let formData = this.objectToFormData(this.data);
      formData.append("file", this.selectedFile);

      this.$refs.btn_simpan.disabled = true;

      const res = await apiUpload(`/v1/pengajuan`, formData);

      if (res.valid) {
        this.submitted = false;
        this.$toast("Berhasil disimpan");

        if (this.data.id < 0) {
          this.data = {
            id: -1,
            id_perusahaan: this.data.id_perusahaan,
            kebutuhan: null,
            jumlah: null,
            keterangan: null,
          };
          this.selectedFile = null;

          this.$refs.input_file.value = null;
          this.$refs.input_kebutuhan.focus();
          this.$refs.btn_simpan.disabled = false;

          this.$emit("reload");
        } else {
          this.$emit("reload");
          this.$router.back();
        }
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pengajuan");
    this.loadPerusahaan();

    this.$refs["input_kebutuhan"].focus();

    if (this.data.id > 0) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
.pengajuan-form {
  h1 {
    margin-top: 0;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .content {
    width: 600px;

    .table-inline {
      input,
      textarea {
        width: calc(100% - 22px);
      }

      select {
        width: 100%;
      }

      .checkbox {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;
        input {
          width: 20px;
          height: 20px;
        }
      }

      table {
        width: 100%;
        tr {
          td {
            &:nth-child(1) {
              width: 100px;
            }
            &:nth-child(2) {
              width: 10px;
            }

            &.btn-group {
              display: flex;
              gap: 10px;
            }
          }
        }
      }

      button {
        width: 150px;
      }
    }

    .invalid {
      border-color: red;
    }
  }

  .bukti {
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 10px;

    img {
      border-radius: 10px;
      object-fit: cover;
      overflow: hidden;
    }
  }
}
</style>
<style>
.daterangepicker.openscenter {
  right: unset !important;
}
</style>