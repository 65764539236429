<template>
  <div class="home">
    <h1>
      {{ data.id < 0 ? "Tambah POS Baru" : "Ubah POS" }}
    </h1>

    <div class="content">
      <form
        class="table-inline"
        @submit.prevent="save()"
        :class="{ saving: loadingSave }"
      >
        <table>
          <tr>
            <td>Nama POS</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.nama" ref="input_nama" />
            </td>
          </tr>
          <tr>
            <td>Untuk Perusahaan</td>
            <td>:</td>
            <td>
              <select
                multiple
                v-model="data.id_perusahaan"
                style="height: 250px"
              >
                <option
                  v-for="(item, index) in dataPerusahaan"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.nama }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="btn-group">
              <button type="submit" class="btn">
                {{ loadingSave ? "MENYIMPAN..." : "SIMPAN" }}
              </button>
              <button
                type="button"
                class="btn btn-outline"
                @click="$router.back()"
              >
                TUTUP
              </button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";
import moment from "moment";

export default {
  name: "Home",
  data() {
    return {
      loadingSave: false,

      dataPerusahaan: [],
      data: {
        id: this.$route.params.id,
        id_perusahaan: [],
      },
    };
  },
  methods: {
    moment,
    async save() {
      if (!this.data.nama) {
        this.$toast("Nama harus diisi");
        return;
      }

      this.loadingSave = true;
      const res = await apiPost(`/v1/pos`, this.data);
      this.loadingSave = false;

      if (res.valid) {
        this.$toast("Berhasil disimpan");
        this.$emit("reload");

        if (this.data.id < 0) {
          this.data.id = -1;
          this.data.nama = "";

          this.$refs["input_nama"].focus();
        } else {
          this.$router.back();
        }
      }
    },
    async load() {
      const res = await apiGet(`/v1/pos?id=${this.data.id}`);
      this.data = res.data;
      this.data.id_perusahaan = [];

      this.data.detail.forEach((item) => {
        this.data.id_perusahaan.push(item.id_perusahaan);
      });

      console.log(this.data);
    },
    async loadPerusahaan() {
      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
  },
  mounted() {
    if (this.data.id > 0) {
      this.load();
    }
    this.loadPerusahaan();

    this.$refs["input_nama"].focus();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;
  margin-top: 0;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.content {
  width: 600px;

  .table-inline {
    select {
      width: 100%;
    }
    input {
      width: calc(100% - 22px);
    }

    table {
      width: 100%;
      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 10px;
          }

          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    button {
      width: 150px;
    }
  }

  select[multiple] {
    option {
      padding: 6px 0;
      &:checked,
      &:focus {
        background-color: var(--blue);
        color: #fff;
      }
    }
  }
}

form.saving {
  opacity: 0.5;
}
</style>