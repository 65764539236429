<template>
  <div class="home">
    <h1>Realisasi {{ data.perusahaan }}</h1>

    <div class="content">
      <div class="table-inline">
        <table>
          <tr>
            <td>Tgl Realisasi</td>
            <td>:</td>
            <td>
              <date-range-picker
                v-model="date"
                :single-date-picker="true"
                :auto-apply="true"
                :ranges="false"
              >
                <template v-slot:input="picker">
                  {{ moment(picker.startDate).format("DD MMM YYYY") }}
                </template>
              </date-range-picker>
            </td>
          </tr>
        </table>

        <div class="table-wrapper">
          <table>
            <thead>
              <tr>
                <th style="width: 50px">#</th>
                <th>Tanggal</th>
                <th>Kebutuhan</th>
                <th style="text-align: right">Jumlah</th>
                <th style="text-align: right; width: 200px">Realisasi</th>
                <th>Keterangan</th>
                <th style="width: 100px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataPengajuan" :key="index">
                <td>{{ ++index }}</td>
                <td>{{ moment(item.tgl_pengajuan).format("DD/MM/YYYY") }}</td>
                <td>{{ item.kebutuhan }}</td>
                <td align="right">{{ item.jumlah | number }}</td>
                <td>
                  <input
                    type="jumlah"
                    v-model="item.realisasi"
                    v-thousand-separator
                    v-select-all
                    style="text-align: right"
                  />
                </td>
                <td>{{ item.keterangan || "-" }}</td>
                <td align="right">
                  <span
                    v-show="item.bukti"
                    class="btn-hapus"
                    @click="selected = item"
                  >
                    Lihat bukti
                  </span>
                </td>
              </tr>

              <tr v-show="data.length <= 0" class="no-hover">
                <td colspan="8">
                  <p style="text-align: center; padding: 100px 0">
                    Tidak ada data
                  </p>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="separator">
                <td colspan="3">
                  <b>TOTAL</b>
                </td>
                <td align="right">
                  <b>{{ totalPengajuan | number }}</b>
                </td>
                <td align="right">
                  <b>{{ totalDirealisasi | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>SALDO KEMARIN</b>
                </td>
                <td></td>
                <td align="right">
                  <input
                    type="jumlah"
                    v-model="data.saldo_kemarin"
                    v-thousand-separator
                    v-select-all
                    style="text-align: right"
                  />
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>KEKURANGAN</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ kekurangan | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>PETTY CASH</b>
                </td>
                <td></td>
                <td align="right">
                  <input
                    type="jumlah"
                    v-model="data.petty_cash"
                    v-thousand-separator
                    v-select-all
                    style="text-align: right"
                  />
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>TOTAL REALISASI</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ totalRealisasi | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>PEMBULATAN</b>
                </td>
                <td></td>
                <td align="right">
                  <b>{{ pembulatan | number }}</b>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr v-if="false">
                <td colspan="7">
                  <div v-if="data.is_realisasi == 1">
                    <p class="sudah-realisasi">
                      Pengajuan sudah direalisasi pada
                      {{ moment(data.updated_at).format("DD-MM-YYYY HH:mm") }}
                    </p>
                  </div>
                  <div v-else>
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-outline"
                        @click="validasi()"
                        v-if="$store.state.setting.login.id != 3"
                      >
                        VALIDASI
                      </button>
                      <button
                        type="button"
                        class="btn btn-green"
                        @click="realisasi()"
                        v-if="$store.state.setting.login.id == 3"
                      >
                        REALISASI
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div class="btn-group">
          <button
            type="button"
            class="btn"
            @click="validasi()"
            v-if="$store.state.setting.login.id != 3"
          >
            VALIDASI
          </button>

          <button
            type="button"
            class="btn btn-green"
            @click="modalOpen = true"
            v-if="$store.state.setting.login.id == 3"
          >
            REALISASI
          </button>

          <button type="button" class="btn btn-outline" @click="$router.back()">
            KEMBALI
          </button>
        </div>
      </div>
    </div>

    <div class="modal" v-if="selected.id" @click="closeModal">
      <div class="modal-body">
        <div class="table-inline">
          <table>
            <tr>
              <td style="width: 100px">Tanggal</td>
              <td style="width: 4px">:</td>
              <td>{{ moment(selected.tgl_pengajuan).format("DD-MM-YYYY") }}</td>
            </tr>
            <tr>
              <td>Kebutuhan</td>
              <td>:</td>
              <td>{{ selected.kebutuhan }}</td>
            </tr>
            <tr>
              <td>Jumlah</td>
              <td>:</td>
              <td>{{ selected.jumlah | number }}</td>
            </tr>
            <tr>
              <td>Keterangan</td>
              <td>:</td>
              <td>{{ selected.keterangan || "-" }}</td>
            </tr>
            <tr>
              <td colspan="3">
                <img :src="selected.bukti" width="100%" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="modal" @click="closeModal" v-show="modalOpen">
      <div class="modal-body">
        <h3>Upload Bukti Transfer</h3>

        <form class="table-inline" @submit.prevent="realisasi()">
          <table>
            <tr>
              <td>Perusahaan</td>
              <td>:</td>
              <td>
                <b>{{ data.perusahaan }}</b>
              </td>
            </tr>
            <tr>
              <td>Pemilik Rekening</td>
              <td>:</td>
              <td>
                <b>{{ data.pemilik_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td>Bank</td>
              <td>:</td>
              <td>
                <b>{{ data.bank }}</b>
              </td>
            </tr>
            <tr>
              <td>No Rekening</td>
              <td>:</td>
              <td>
                <b>{{ data.no_rekening }}</b>
              </td>
            </tr>
            <tr>
              <td>Bukti Transfer</td>
              <td>:</td>
              <td>
                <input
                  type="file"
                  @change="onFileChange"
                  ref="input_file"
                  accept="image/*"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <button
                  type="submit"
                  class="btn btn-green"
                  :disabled="loadingRealisasi"
                  style="width: 100%"
                >
                  REALISASI
                </button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { apiGet, apiPost, apiUpload } from "../api";

export default {
  name: "RealisasiForm",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      loading: false,
      loadingRealisasi: false,

      dataPengajuan: [],

      data: {
        id: this.$route.params.id,
      },
      selectedFile: null,
      date: {
        startDate: moment().format("YYYY-MM-DD"),
      },

      selected: {},
      modalOpen: false,
      action: null,
    };
  },
  computed: {
    totalPengajuan() {
      let total = 0;
      this.dataPengajuan.forEach((item) => {
        total += parseInt(item.jumlah);
      });

      return total;
    },
    totalDirealisasi() {
      let total = 0;
      this.dataPengajuan.forEach((item) => {
        const realisasi = item.realisasi.toString().replace(/\./g, "");
        total += parseInt(realisasi);
      });

      return total;
    },
    kekurangan() {
      const jumlah =
        parseInt(this.totalDirealisasi) -
        parseInt(
          (this.data.saldo_kemarin || "0").toString().replace(/\./g, "")
        );

      return jumlah;
    },
    totalRealisasi() {
      return (
        parseInt(this.kekurangan) +
        parseInt((this.data.petty_cash || "0").toString().replace(/\./g, ""))
      );
    },
    pembulatan() {
      const pembulatan = 50000;
      const selisih = this.totalRealisasi % pembulatan;
      const dasar = this.totalRealisasi - selisih;
      const hasil = dasar % 100000;

      if (this.totalRealisasi % pembulatan == 0) {
        return this.totalRealisasi;
      } else {
        if (hasil < pembulatan) {
          return dasar;
        } else {
          return dasar + pembulatan;
        }
      }
    },
  },
  methods: {
    moment,
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.selected = {};
        this.modalOpen = false;
      }
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      const maxFileSizeMB = 2;
      const fileSizeMB = this.selectedFile.size / (1024 * 1024);

      if (fileSizeMB > maxFileSizeMB) {
        alert(
          `Ukuran file terlalu besar. Ukuran maksimum adalah ${maxFileSizeMB} MB.`
        );
        return;
      }
    },
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/realisasi?id=${this.data.id}`);

      setTimeout(() => {
        this.data = res.data;
        this.data.petty_cash = 500000;
        this.data.saldo_kemarin = res.data.saldo_kemarin || 0;

        this.dataPengajuan = this.data.detail;
        this.dataPengajuan.forEach((item) => {
          // kalo udah ada total_direalisasi berarti udah divalidasi, jadi ambil data yang sudah divalidasi
          item.realisasi = this.data.total_direalisasi
            ? this.$options.filters.number(item.realisasi)
            : this.$options.filters.number(item.jumlah);
        });

        this.getSaldo(this.data.id_perusahaan);

        this.$loading(false);
      }, 150);
    },

    objectToFormData(obj) {
      const formData = new FormData();

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          formData.append(key, obj[key]);
        }
      }
      return formData;
    },

    async validasi() {
      if (
        confirm(
          this.action == "realisasi" ? "Realisasi Pengajuan?" : "Validasi Data?"
        )
      ) {
        this.data.action =
          this.action == "realisasi" ? "realisasi" : "validasi";
        this.data.tgl_realisasi = moment(this.date.startDate).format(
          "YYYY-MM-DD"
        );

        this.data.total_pengajuan = this.totalPengajuan;
        this.data.total_direalisasi = this.totalDirealisasi;
        this.data.kekurangan = this.kekurangan;
        this.data.total_realisasi = this.totalRealisasi;
        this.data.pembulatan = this.pembulatan;
        this.data.saldo_kemarin = parseInt(
          this.data.saldo_kemarin.toString().replace(/\./g, "")
        );
        this.data.petty_cash = parseInt(
          this.data.petty_cash.toString().replace(/\./g, "")
        );

        const detail = [];
        this.dataPengajuan.forEach((item) => {
          detail.push({
            id_pengajuan: item.id,
            realisasi: parseInt(item.realisasi.toString().replace(/\./g, "")),
          });
        });
        this.data.detail = JSON.stringify(detail);

        // Convert to form data
        let formData = this.objectToFormData(this.data);
        if (this.selectedFile) {
          formData.append("file", this.selectedFile);
        }

        this.$loading(true);
        await apiUpload(`/v1/realisasi/save`, formData);

        location.href = "/realisasi";
      }

      // if (this.action == "realisasi") {
      //   await apiPost(`/v1/realisasi`, this.data);
      //   this.actiono = null;
      // } else {
      // }
    },

    realisasi() {
      this.loadingRealisasi = true;
      this.action = "realisasi";
      this.validasi();
    },

    async getSaldo(id_perusahaan) {
      const res = await apiGet(
        `/v1/saldo?id_perusahaan=${id_perusahaan}`
      );

      if (res.data) {
        this.data.saldo_kemarin = res.data.saldo;
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "realisasi");

    this.load();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.content {
  .table-inline {
    table {
      width: 420px !important;
    }

    .table-wrapper {
      margin-top: 10px;
      margin-bottom: 20px;

      height: calc(100vh - 280px);
      overflow: auto;

      table {
        width: 100% !important;
      }
    }

    input,
    textarea {
      width: calc(100% - 22px);
    }

    select {
      width: 100%;
    }

    table {
      width: 100%;
      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 10px;
          }

          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }

        &.separator {
          td {
            border-top: 2px solid #ccc;
          }
        }

        &:hover {
          td {
            background: none;
            cursor: default;
          }
        }
      }

      tfoot {
        tr {
          td {
            border-bottom: 0;
          }
        }
      }
    }

    button {
      width: 150px;
    }
  }

  .btn-group {
    display: flex;
    gap: 10px;
  }
}

.sudah-realisasi {
  background: var(--green);
  color: #fff;
  padding: 20px;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
}

.modal-body {
  max-width: 900px;
  h3 {
    margin-top: 0;
  }
}
</style>