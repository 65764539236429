<template>
  <div class="home">
    <h1>Home</h1>

    <div class="content">
      <div class="grid">
        <div class="card">
          <h1>10</h1>
          <p>Pengajuan bulan ini</p>
        </div>
        <div class="card">
          <h1>10</h1>
          <p>Realisasi bulan ini</p>
        </div>
        <div class="card">
          <h1>10</h1>
          <p>Transaksi bulan ini</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  mounted() {
    this.$store.commit("setting/setActivePage", "home");
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
}
.content {
  margin-top: 40px;
}
.card {
  h1,
  p {
    margin: 0;
  }
  p {
    color: #777;
    margin-top: 15px;
  }
}
</style>