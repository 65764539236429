<template>
  <div class="home">
    <h1>Resume Realisasi</h1>

    <div class="content">
      <div class="top-bar">
        <div v-if="false"></div>
        <div class="filter" v-if="false">
          <p>Filter</p>
          <form @submit.prevent="load()">
            <input
              type="date"
              placeholder="Tgl Order"
              v-model="filter.tgl_order"
            />
            <select v-model="filter.realisasi">
              <option value="SEMUA">Semua</option>
              <option value="-1">Belum Realisasi</option>
              <option value="1">Sudah Realisasi</option>
            </select>
            <select v-model="filter.id_perusahaan">
              <option value="SEMUA">Semua Perusahaan</option>
              <option
                :value="item.id"
                v-for="(item, index) in dataPerusahaan"
                :key="index"
              >
                {{ item.nama }}
              </option>
            </select>
            <button type="submit" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Tanggal</th>
              <th>Perusahaan</th>
              <th>Pemilik Rekening</th>
              <th>Bank</th>
              <th>No Rekening</th>
              <th style="text-align: right">Jumlah Transfer</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              @click="$to(`/realisasi/${item.id_realisasi}`)"
            >
              <td>{{ ++index }}</td>
              <td>{{ moment(item.tgl_realisasi).format("DD/MM/YYYY") }}</td>
              <td>{{ item.perusahaan }}</td>
              <td>{{ item.pemilik_rekening }}</td>
              <td>{{ item.bank }}</td>
              <td>{{ item.no_rekening }}</td>
              <td align="right">{{ item.pembulatan | number }}</td>
              <td>{{ item.is_realisasi == 1 ? 'Sudah Ditransfer' : 'Perlu Ditransfer' }}</td>
            </tr>

            <tr v-show="data.length <= 0" class="no-hover">
              <td colspan="11">
                <p style="text-align: center; padding: 100px 0">
                  Tidak ada data
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" v-if="dataBank.id" @click="closeModal">
      <div class="modal-body">
        <div class="table-inline">
          <table>
            <tr>
              <td>Nama</td>
              <td>:</td>
              <td>{{ dataBank.nama }}</td>
            </tr>
            <tr>
              <td>Bank</td>
              <td>:</td>
              <td>{{ dataBank.bank }}</td>
            </tr>
            <tr>
              <td>No Rekening</td>
              <td>:</td>
              <td>{{ dataBank.no_rekening }}</td>
            </tr>
            <tr>
              <td>Jumlah Transfer</td>
              <td>:</td>
              <td>{{ dataBank.jumlah | number }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet } from "../api";

import moment from "moment";

export default {
  name: "Order",
  data() {
    return {
      data: [],
      dataPerusahaan: [],
      dataBank: {},

      filter: {
        is_realisasi: -1,
        id_perusahaan: "SEMUA",
      },
    };
  },
  methods: {
    moment,
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.dataBank = {};
      }
    },
    async loadPerusahaan() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan`);
      this.dataPerusahaan = res.data;
    },
    async load() {
      this.$loading(true);
      const filter = this.$objectToQuery(this.filter);

      const res = await apiGet(`/v1/realisasi?${filter}`);

      setTimeout(() => {
        this.data = res.data;
        this.$loading(false);
      }, 150);
    },
    async getBank(item) {
      const res = await apiGet(
        `/v1/user/bank?id_perusahaan=${item.id_perusahaan}`
      );

      setTimeout(() => {
        this.dataBank = res.data;
        this.dataBank.jumlah = item.pembulatan;
      }, 150);
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "realisasi/resume");
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 90px; //45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 200px 200px 200px 100px;
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 240px);
}
</style>