import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast, {
  position: 'top-right',
  type: 'default'
});

Vue.config.productionTip = false

Vue.prototype.$to = (url) => {
  setTimeout(() => {
    router.push(url);
  }, 150);
}

Vue.prototype.$loading = (val) => {
  store.commit("setting/setLoading", val);
}

Vue.prototype.$toast = (text) => {
  Vue.$toast.open(text);
}

Vue.prototype.$objectToQuery = (params) => {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&');
}

Vue.filter('number', function (value) {
  if (value || value == 0)
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  else
    return ''
});

Vue.directive('thousand-separator', {
  update(el) {
    let value = parseInt(el.value.replace(/[^0-9]+/g, ""));
    if (!isNaN(value)) {
      el.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
});

Vue.directive('select-all', {
  inserted(el) {
    el.addEventListener('focus', function() {
      this.select();
    });
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
