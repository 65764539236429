<template>
  <div class="home">
    <h1>{{ data.id > 0 ? 'Ubah' : 'Tambah' }} Perusahaan</h1>

    <div class="content">
      <form class="table-inline" @submit.prevent="save()">
        <table>
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td>
              <input type="text" v-model="data.nama" />
            </td>
          </tr>
          <tr>
            <td>Alamat</td>
            <td>:</td>
            <td>
              <textarea rows="3" v-model="data.alamat"></textarea>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td class="btn-group">
              <button type="submit" class="btn">SIMPAN</button>
              <button
                type="button"
                class="btn btn-outline"
                @click="$router.go(-1)"
              >
                KEMBALI
              </button>
            </td>
          </tr>
        </table>
      </form>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "./../api";

export default {
  name: "PengajuanForm",
  data() {
    return {
      loading: false,
      data: {
        id: this.$route.params.id,
      },
    };
  },
  methods: {
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/perusahaan?id=${this.data.id}`);
      this.data = res.data;

      this.$loading(false);
    },
    async save() {
      if (!this.data.nama) {
        return;
      }

      this.$loading(true);

      await apiPost(`/v1/perusahaan`, this.data);
      
      this.$router.back();
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "perusahaan");

    if (this.data.id > 0) {
      this.load();
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    cursor: pointer;
  }
}

.content {
  width: 600px;

  .table-inline {
    input,
    textarea {
      width: calc(100% - 22px);
    }

    table {
      width: 100%;
      tr {
        td {
          &:nth-child(1) {
            width: 100px;
          }
          &:nth-child(2) {
            width: 10px;
          }

          &.btn-group {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    button {
      width: 150px;
    }
  }
}
</style>