<template>
  <div class="home">
    <h1>Master POS</h1>

    <div class="content">
      <div class="top-bar">
        <div>
          <router-link to="/pos/-1" class="btn">TAMBAH</router-link>
        </div>
        <div class="filter">
          <p>Filter</p>
          <form @submit.prevent="load()">
            <input type="text" placeholder="Cari..." v-model="filter.nama" />
            <button type="button" class="btn btn-filter">FILTER</button>
          </form>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Nama POS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              @click="$router.push(`/pos/${item.id}`)"
            >
              <td>{{ ++index }}</td>
              <td>{{ item.nama }}</td>
              <td align="right">
                <span class="btn-hapus" @click.stop="hapus(item)">Hapus</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal" v-if="$route.params.id" @click="closeModal">
      <div class="modal-body">
        <PosForm @reload="load()" />
      </div>
    </div>
  </div>
</template>

<script>
import { apiGet, apiPost } from "../api";

import moment from "moment";
import PosForm from "./PosForm.vue";

export default {
  name: "Pos",
  components: {
    PosForm,
  },
  data() {
    return {
      loading: false,
      data: [],
      filter: {
        nama: "",
      },
    };
  },
  methods: {
    moment,
    closeModal(e) {
      if (e.target.classList[0] == "modal") {
        this.$router.back();
      }
    },
    async load() {
      this.$loading(true);

      const res = await apiGet(`/v1/pos`);

      setTimeout(() => {
        this.data = res.data;
        this.$loading(false);
      }, 150);
    },
    async hapus(item) {
      if (confirm("Hapus?")) {
        this.$loading(true);
        await apiPost(`/v1/pos/delete`, { id: item.id });

        this.load();
      }
    },
  },
  mounted() {
    this.$store.commit("setting/setActivePage", "pos");

    this.load();
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  margin-top: 45px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  a {
    width: 150px;
    text-align: center;
  }

  .filter {
    position: relative;

    p {
      position: absolute;
      font-size: 14px;
      top: -40px;
    }
  }
  form {
    display: grid;
    grid-template-columns: 200px 100px;
    grid-column-gap: 15px;

    .btn.btn-filter {
      background-color: var(--green) !important;
      border: 0 !important;
    }
  }
}
.table-wrapper {
  height: calc(100vh - 240px);
}
</style>